import { I18nProvider } from 'next-localization';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';

import { SitecorePageProps } from 'lib/page-props';

import ErrorBoundary from 'components/BaseHelpers/ErrorBoundary';

import '../styles/global/global.scss';

function App({ Component, pageProps }: AppProps<SitecorePageProps>): JSX.Element {
  const { dictionary, ...rest } = pageProps;
  const isExperienceEditor = rest.layoutData?.sitecore?.context?.pageEditing;

  // Use the next-localization (w/ rosetta) library to provide our translation dictionary to the app.
  // Note Next.js does not (currently) provide anything for translation, only i18n routing.
  // If your app is not multilingual, next-localization and references to it can be removed.
  return (
    <>
      <div id={isExperienceEditor ? 'exp-editor' : undefined}>
        <ErrorBoundary>
          <I18nProvider lngDict={dictionary} locale={pageProps.locale}>
            <Component {...rest} />
          </I18nProvider>
        </ErrorBoundary>
      </div>
      <ThirdPartyScripts />
    </>
  );
}

const ThirdPartyScripts = (): JSX.Element => {
  if (process.env.NODE_ENV !== 'production') {
    return <></>;
  }

  return (
    <>
      <Head>
        <meta
          name="google-site-verification"
          content="oVIiQDQQ3Ce-cPUBumZHSE3Y8jy4IKH7cAVrHqJF1sQ"
        />
        <script
          id="navigate-to-single-slash-url"
          dangerouslySetInnerHTML={{
            __html: `
(function () {
  const { pathname, href } = window.location;
  if (pathname.startsWith("//")) {
    window.location.href = href.replace(pathname, pathname.replace(/^\\/{2,}/, "/"));
  }
})();`,
          }}
        />
      </Head>
      {/* Google Tag Manager */}
      <Script id="google-tag-manager">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5KMCJB6');`}</Script>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=GTM-5KMCJB6`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>

      {/* OneTrust */}
      <Script
        id="one-trust-1"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        strategy="lazyOnload"
        data-domain-script="b221b6a7-5ee4-4bc5-b191-105e091ecbf5"
      ></Script>
      <Script id="one-trust-2" strategy="lazyOnload">{`function OptanonWrapper() {}`}</Script>

      {/* Font Tracking */}
      <Script
        id="font-load-1"
        strategy="lazyOnload"
      >{`var MTUserId = 'd7e3ce2e-8d0e-4d98-85ee-24bc98f711d5';var MTFontIds = [1475508, 1475514, 1475532, 1475538, 1475544];`}</Script>
      <Script id="font-load-2" src="/mtiFontTrackingCode.js" strategy="lazyOnload" />
    </>
  );
};

export default App;
