import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import Head from 'next/head';

import { useSite } from 'src/hooks/useSite';
import { useTemplate } from 'src/hooks/useTemplate';

import { ComponentProps } from 'lib/component-props';

export type MetadataMetadataProps = ComponentProps & {
  fields: {
    title: string;
    description: string;
    keywords: string;
    sectionTitle: string;
    pageTitle: string;
    openGraphUrl: string;
    openGraphType: string;
    openGraphTitle: string;
    openGraphDescription: string;
    openGraphImage: string;
    twitterCard: string;
    twitterSite: string;
    twitterTitle: string;
    twitterDescription: string;
    twitterImage: string;
    canonicalUrl: string;
    alternateLinks: AlternateLink[];
  };
};

type AlternateLink = {
  url: string;
  hrefLang: string;
};

const MetadataMetadata = (props: MetadataMetadataProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const ldJson = sitecoreContext.ldJson as Record<string, string>;
  const addProductJsonLd = () => ({ __html: `${JSON.stringify(ldJson?.structuredData)}` });
  const { isPeopleDetail } = useTemplate();

  const {
    title,
    description,
    keywords,
    sectionTitle,
    pageTitle,
    openGraphUrl,
    openGraphDescription,
    openGraphImage,
    openGraphTitle,
    openGraphType,
    canonicalUrl,
    twitterCard,
    twitterDescription,
    twitterImage,
    twitterSite,
    twitterTitle,
  } = props.fields;

  const alternateLinks = useAltLinks(props.fields.alternateLinks, canonicalUrl);

  return (
    <>
      {props.fields && (
        <Head>
          <title>{title}</title>

          <meta name="title" content={title} />
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />

          <meta name="sectiontitle" content={sectionTitle} />
          <meta name="pagetitle" content={pageTitle} />

          <meta property="og:url" content={openGraphUrl || canonicalUrl} />
          <meta property="og:type" content={openGraphType || 'website'} />
          <meta property="og:title" content={openGraphTitle} />
          <meta property="og:description" content={openGraphDescription} />

          {openGraphImage && (
            <meta property="og:image" content={resizeShareImage(openGraphImage, isPeopleDetail)} />
          )}

          <meta name="twitter:card" content={twitterCard} />
          <meta name="twitter:site" content={twitterSite} />
          <meta name="twitter:title" content={twitterTitle} />
          <meta name="twitter:description" content={twitterDescription} />

          {twitterImage && <meta name="twitter:image" content={twitterImage} />}

          {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

          {alternateLinks.map((alternateLink, index) => (
            <link
              key={`link-${index}`}
              rel="alternate"
              hrefLang={alternateLink.hrefLang}
              href={alternateLink.url}
            />
          ))}

          {ldJson && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={addProductJsonLd()}
              key="product-jsonld"
            />
          )}

          <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        </Head>
      )}
    </>
  );
};

const resizeShareImage = (url = '', isPeopleDetail: boolean) => {
  if (process.env.NEXT_PUBLIC_RESIZE_IMAGES === 'true') {
    const updatedUrl = new URL(url);
    // We are resizing og:image to 400px wide so that on linked in they are displayed in the
    // small image card style. On People detail images, we are cropping and focusing the image
    // towards the top so the face doesnt get cropped off
    if (isPeopleDetail) {
      updatedUrl.pathname = `/cdn-cgi/image/width=400,height=208quality=90,format=jpeg,fit=cover,gravity=0.5x0.08${updatedUrl.pathname}`;
    } else {
      updatedUrl.pathname = `/cdn-cgi/image/width=400,quality=90,format=jpeg${updatedUrl.pathname}`;
    }
    return updatedUrl.toString();
  }
  return url;
};

const useAltLinks = (alternateLinks: AlternateLink[], canonicalUrl: string): AlternateLink[] => {
  const { isMainSite } = useSite();

  const mainLangs = ['en', 'de', 'fr', 'pt', 'zh-hans', 'es', 'ja'];
  const tcLangs = ['en', 'pt'];
  const langs = isMainSite ? mainLangs : tcLangs;

  // the `alternateLinks` from the layout service may omit languages that do not have a
  // translation. However, since the site has English fallback, we still want those pages
  // to have alternate links.
  const missingLangs = langs.filter(
    (lang) =>
      !alternateLinks.some((link) => {
        const urlParts = new URL(link.url.toLowerCase());
        return urlParts.pathname.startsWith(`/${lang}`);
      })
  );

  const missingAlternateLinks: AlternateLink[] = missingLangs.map((lang) => {
    const urlParts = new URL(canonicalUrl);
    urlParts.pathname = urlParts.pathname.replace(/^\/[a-zA-Z-]*\//, `/${lang}/`);

    return {
      hrefLang: lang,
      url: urlParts.toString(),
    };
  });

  // Alt Links for all languages
  const allAltLinks = [...alternateLinks, ...missingAlternateLinks];

  // zh-hans is invalid as an hreflang. We need to convert it to zh-cn
  // to meet the ISO 3166-1 alpha-2 standard for country codes
  const formattedAltLinks = allAltLinks.map((link) => ({
    url: link.url,
    hrefLang: link.hrefLang.toLowerCase() === 'zh-hans' ? 'zh-cn' : link.hrefLang,
  }));

  return formattedAltLinks;
};
export default MetadataMetadata;
